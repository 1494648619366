import * as React from "react"
import type { HeadFC } from "gatsby"
import Navigation from "../shared/Navigation"



const IndexPage = () => {
  return (
    <>
    <Navigation />
    <main>
        Syne - connecting people with rare disease
    </main>
    </>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Welcome To Syne</title>
